import React from "react";
import ReactDOM from "react-dom";
import LionLaser from "./lightstained 3.jpg";
import { Link } from "react-router-dom";
class Landing extends React.Component {
  render() {
    return (
      <div className="block is-4by3 pic-container is-full-width">
        <div>
          <figure className="image ">
            <img
              src={LionLaser}
              alt="Lion LED art and Lasers"
              title="Lion LED art and Lasers"
              className="has-ratio totem-pic"
            />
          </figure>
          <div className="container">
            <div className="section">
              <div className="is-size-1">light given form.</div>
            </div>
            <div className="section">
              <div className="block is-flex is-justify-content-space-around is-size-3">
                <div className="card has-background-dark">
                  <div className="card-header">
                    <div className="card-header-title has-text-white">
                      <Link
                        to="/art"
                        className="has-text-white external-link is-size-6"
                      >
                        LED Art
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card has-background-dark">
                  <div className="card-header ">
                    <div className="card-header-title has-text-white">
                      <Link
                        to="/lasers"
                        className="has-text-white external-link is-size-6"
                      >
                        Lasers
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Landing;
