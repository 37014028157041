import React from "react";
class Other extends React.Component {
  render() {
    return (
      <div className="block">
        <div className="section is-large">
          <div className="is-size-1 block">other njineering</div>
          <div className="block is-flex is-justify-content-space-around is-size-3">
            <div className="card has-background-dark">
              <div className="card-header">
                <div className="card-header-title has-text-white ">
                  <a
                    href="https://ivydocuments.com"
                    className="has-text-white external-link is-size-6"
                  >
                    Ivy Documents
                  </a>
                </div>
              </div>
            </div>
            <div className="card has-background-dark">
              <div className="card-header ">
                <div className="card-header-title has-text-white is-size-6">
                  <a
                    href="https://www.instagram.com/njinuity/"
                    className="has-text-white external-link"
                  >
                    Calligraphy
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Other;
