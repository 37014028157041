import React from "react";
import Lion from "./lightstained 2.jpg";
import Wings from "./IMG_4428.png";
import Elephant from "./IMG_3067_Moment.jpg";
import Carousel from "react-bootstrap/Carousel";
class Animals extends React.Component {
  constructor(props) {
    super();
    this.state = {
      carouselCount: 0,
    };
  }
  handleSelect = (selectedIndex, e) => {
    this.setState({ carouselCount: selectedIndex });
  };
  render() {
    var w = window.innerWidth;
    return (
      <div className="block has-text-centered animals-container">
        <div className="container">
          <div className="section">
            <div className="is-size-1">Stained glass, modernized </div>
            <div className="is-size-3">Endless colors, endless patterns</div>
          </div>
        </div>
        <Carousel
          activeIndex={this.state.carouselCount}
          onSelect={this.handleSelect}
          interval={8000}
          className="animals-carousel"
        >
          <Carousel.Item>
            <figure className="image is-4by5">
              <img src={Lion} className=" animals-carouselImage" />
            </figure>
            <Carousel.Caption
              bsPrefix={
                w > 600 ? "carousel-captionLarge" : "carousel-CaptionCustom"
              }
              className="is-size-3 is-size-6-mobile px-6"
            >
              <div>Lion</div>
              <div>3 ft x 3.5 ft</div>
              <div>Available for sale or rental. Contact for pricing.</div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <figure className="image is-4by5">
              <img src={Wings} className="animals-carouselImage" />
            </figure>
            <Carousel.Caption
              bsPrefix={
                w > 600 ? "carousel-captionLarge" : "carousel-CaptionCustom"
              }
              className="is-size-3 is-size-6-mobile px-6"
            >
              <div>Wings</div>
              <div>9 ft x 4 ft</div>
              <div>Available for sale or rental. Contact for pricing.</div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <figure className="image is-4by5">
              <img src={Elephant} className="animals-carouselImage" />
            </figure>
            <Carousel.Caption
              bsPrefix={
                w > 600 ? "carousel-captionLarge" : "carousel-CaptionCustom"
              }
              className="is-size-3 is-size-6-mobile px-6"
            >
              <div>Elephant</div>
              <div>2 ft x 2 ft</div>
              <div>Available for sale or rental. Contact for pricing.</div>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
    );
  }
}

export default Animals;
