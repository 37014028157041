import React from "react";
import Carousel from "react-bootstrap/Carousel";
class Lasers extends React.Component {
  constructor(props) {
    super();
    this.state = {
      carouselCount: 0,
    };
  }
  handleSelect = (selectedIndex, e) => {
    this.setState({ carouselCount: selectedIndex });
  };
  render() {
    return (
      <div className="has-background-black tile is-ancestor has-text-white ">
        <div className="section is-medium tile is-vertical ">
          <h1 class="title has-text-white">Laser Rentals and Bookings</h1>
          <div className="p-3 is-size-5">
            My lasers are carefully maintained and curated to be perfect for
            your event. All lasers are aligned and maintained to avoid color
            halos. All projectors come with a custom color calibration and
            settings to ensure color accuracy and reliable performance.
          </div>
          <div className="p-3 is-size-5">
            A Beyond Advanced license is available for rental or is included in
            the operator fee.
          </div>
          <div className="p-3 is-size-5">
            My laser fleet ranges from 3-10 Watts. I am a FDA licensed laser
            operator and a ILDA certified Laser Safety Officer to ensure your
            event is both compliant and safe.
          </div>
          <div className="mt-6 p-3 is-size-4">
            Interested? Reach out at lightstainedarts@gmail.com.
          </div>
          <div className="is-medium">
            <div className="is-size-3 ">Tours</div>
            <div> Eric Prydz Presents Holo - Laser Tech - ER Productions</div>
            <div>The Pink Floyd Laser Spectacular - Laser Designer</div>
            <div className="is-size-3 ">Festivals</div>
            <div>Freaky Deaky 2023</div>
            <div>Burning Man 2023 - The Fluffy Cloud</div>
            <div>Seismic Dance Event 5.0 Afterhours</div>
          </div>
        </div>

        <div className="section is-medium">
          <h1 class="title has-text-white">Laser Shows</h1>
          <div className="container">
            <iframe
              height="1000vw"
              width="1000vw"
              src="https://www.youtube.com/embed/videoseries?si=Hv027MgF0NATZMuY&amp;list=PLQ3r8PUORKG3d9G9YcxuJ6bXI_m_jHasF"
              title="Lightstained Timecode"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    );
  }
}

export default Lasers;
