import React from "react";
import { Link } from "react-router-dom";
class Header extends React.Component {
  constructor(props) {
    super();
    this.state = {
      showNavBurger: false,
    };
  }
  onClick = () => {
    this.setState({ showNavBurger: !this.state.showNavBurger });
  };

  render() {
    return (
      <nav
        class="navbar has-background-black has-text-white mb-1"
        role="navigation"
        aria-label="main navigation"
      >
        <div class="navbar-brand has-text-white">
          <Link to="/" class="navbar-item has-text-white mx-4">
            Lightstained
          </Link>
        </div>
        <a
          role="button"
          class={
            this.state.showNavBurger
              ? "navbar-burger is-active"
              : "navbar-burger "
          }
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
          onClick={this.onClick}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
        <div
          id="navbarBasicExample"
          class={
            this.state.showNavBurger
              ? "navbar-menu navbar-add has-background-black is-active"
              : "navbar-menu navbar-add has-background-black "
          }
        >
          <div class="navbar-start navbar-add has-text-centered">
            <Link
              to="/art"
              class=" navbar-item has-text-white has-background-black"
            >
              LED Art
            </Link>
            <Link
              to="/lasers"
              class="navbar-item has-text-white has-background-black"
            >
              Lasers
            </Link>
          </div>
          <div class="navbar-end navbar-add has-text-centered">
            <div class="navbar-item has-text-white navbar-email has-background-black">
              lightstainedarts@gmail.com
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default Header;
