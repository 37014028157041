import React from "react";
import Totem from "./totem.jpg";
import "./Infinity.css";
class Infinity extends React.Component {
  render() {
    return (
      <div className="block">
        <figure className="image is-4by3">
          <img
            src={Totem}
            alt="Spinning Infinity Dodecahedron at EDC"
            title="Spinning Infinity Dodecahedron at Electric Daisy Carnival"
            className="image SID-container"
          />
        </figure>
        <div className="infinity-text-background" />
      </div>
    );
  }
}

export default Infinity;
