import logo from './logo.svg';
import './App.css';
import MainPage from './components/MainPage';
import LEDs from './components/LEDs/LEDs';
import Lasers from './components/Lasers/Lasers';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import Header from './components/Header/Header';
const App =() =>{
  return (
    <>
            <Header/>
              <Routes>
                <Route path ="/" element={<MainPage/>} />
                <Route path ="/art" element={<LEDs/>} />
                <Route path ="/lasers" element={<Lasers/>} />
              </Routes>
              <footer class="footer has-background-black has-text-centered">
          Copyright Lightstained Arts 2022. All Rights Reserved.
        </footer>
              </>

  );
}

export default App;
