import React from "react";
import Xandery from "./xandery.mov";
class CustomWork extends React.Component {
  render() {
    return (
      <div className="block has-text-centered">
        <div className="custom-work-text is-size-3">
          <div className="container">
            <div className=" section">
              <div className="is-size-3">Custom LED Art </div>
              <div className="is-size-4 is-size-6-mobile">
                Have an idea? Talk to me at lightstainedarts@gmail.com and let's
                bring your vision to life.
              </div>
            </div>
          </div>
        </div>
        <video
          src={Xandery}
          loop
          muted
          controls
          webkit-playsinline
          playsinline
          playsInline
          webkit-playsInline
          className="xanderyVid mt-6"
          alt="Custom LED signage"
          title="Custom LED Signage made for client"
        />
      </div>
    );
  }
}

export default CustomWork;
