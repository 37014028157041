import React from "react";
import Animals from "./Animals/Animals";
import Infinity from "./Infinity/Infinity";
import CustomWork from "./Custom Work/CustomWork";
import "./LEDs.css";
class LEDs extends React.Component {
  render() {
    return (
      <div className="has-background-black has-text-white leds-container">
        <Animals />
        <div className="spacer" />
        <Infinity />
        <CustomWork />
      </div>
    );
  }
}

export default LEDs;
