import React from "react";

import Header from "./Header/Header.jsx";
import Landing from "./Landing/Landing";
import "./MainPage.css";
import Other from "./Other/Other";
class MainPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sentForm: false,
      email: "",
    };
  }
  onChangeEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  render() {
    return (
      <div className="has-background-black has-text-white ">
        <div className="has-background-black has-text-white has-text-centered">
          <Landing />

          <Other />
        </div>
      </div>
    );
  }
}

export default MainPage;
